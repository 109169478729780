body { 
  /* Image Location */ 
  background-image: url("./assets/img/bg.jpg"); 

  /* Background image is centered vertically and horizontally at all times */ 
  background-position: center center; 
  background-repeat: no-repeat; 
  background-attachment: fixed; 
  background-size: cover; 
  background-color: #464646; 

  /* Fonts */ 
  font-family: 'Lora', serif;
  font-weight: normal;
  line-height: 1.6em;
  font-size: 100%;
} 

div {
  display: block;
}

.wrapper-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}


/* Quote */
blockquote {
  margin: 3.7em auto;
  padding: 1em;
  background: #000;
  border: 0.5em solid transparent;
  box-shadow: 5px 3px 30px black;
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.5;
  width: 60%;
  color: #eeeeee;
}

footer {
  padding-top: 1.3em;
}

cite {
  font-style: normal;
  font-size: 1em;
  font-weight: bold;
}

@import url(https://fonts.googleapis.com/css?family=Lora);

